body.maintenance-page {
  font: 13px/1.5 "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, sans-serif;

  #page {
    margin: 5% 15%;
    padding: 2em;
  }

  #header {
    margin-bottom: 8px;
  }

  #page-title {
    line-height: 1em;
    margin-top: 0;
  }
}
